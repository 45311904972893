export default function SVGSettings() {
    return (
        <svg width="25" height="25" viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M29.855 1.8954C29.5188 1.32321 29.0401 0.84787 28.4655 0.515701C27.891 0.183532 27.2402 0.00586129 26.5765 0H11.3877C10.724 0.00586129 10.0732 0.183532 9.49867 0.515701C8.9241 0.84787 8.44538 1.32321 8.10915 1.8954L0.514735 15.048C0.177526 15.632 0 16.2946 0 16.969C0 17.6434 0.177526 18.3059 0.514735 18.89L8.10915 31.9913C8.44538 32.5635 8.9241 33.0388 9.49867 33.371C10.0732 33.7032 10.724 33.8809 11.3877 33.8867H26.5765C27.2402 33.8809 27.891 33.7032 28.4655 33.371C29.0401 33.0388 29.5188 32.5635 29.855 31.9913L37.4495 18.8388C37.7867 18.2547 37.9642 17.5922 37.9642 16.9177C37.9642 16.2433 37.7867 15.5808 37.4495 14.9967L29.855 1.8954ZM25.3855 16.9434C25.3855 18.2098 25.0099 19.4479 24.3063 20.5009C23.6027 21.5539 22.6026 22.3747 21.4326 22.8593C20.2625 23.344 18.975 23.4708 17.7329 23.2237C16.4907 22.9766 15.3498 22.3668 14.4542 21.4712C13.5587 20.5757 12.9488 19.4347 12.7018 18.1926C12.4547 16.9505 12.5815 15.663 13.0661 14.4929C13.5508 13.3228 14.3715 12.3228 15.4246 11.6191C16.4776 10.9155 17.7156 10.54 18.9821 10.54C20.6804 10.54 22.3091 11.2146 23.51 12.4155C24.7108 13.6163 25.3855 15.2451 25.3855 16.9434Z" fill="url(#paint0_linear_516_1393)" />
            <defs>
                <linearGradient id="paint0_linear_516_1393" x1="19.2665" y1="33.5668" x2="19.2665" y2="5.24537" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFCE56" />
                    <stop offset="1" stopColor="#FE9923" />
                </linearGradient>
            </defs>
        </svg>
    );
}