import LOADER from '@/assets/svg/static/loader.svg';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';

export default function Loader({
  show = true,
}) {
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, [show]);
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          className="loader"
          initial={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
        >
          <div className="loader__wrapper">
            <div className="loader__inset">
              <div className="loader__row1">
                <span>
                  Afrimex
                </span>
              </div>
              <div className="loader__row2">
                <span>
                  Gold
                </span>
              </div>
            </div>
          </div>
          <img src={LOADER} alt="" />
        </motion.div>
      )}
    </AnimatePresence>
  );
}