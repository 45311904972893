import { createPortal } from "react-dom";
import Loader from "@/components/Loader";
import { NavLink } from "react-router-dom";
import SVGDashboard from "@/assets/svg/menu/SVGDashboard";
import { APP_ROUTES } from "@/features/constants";
import SVGWallet from "@/assets/svg/menu/SVGWallet";
import SVGGold from "@/assets/svg/menu/SVGGold";
import SVGCareer from "@/assets/svg/menu/SVGCareer";
import SVGTeam from "@/assets/svg/menu/SVGTeam";
import { useEffect } from "react";

export default function AppHolder({
  topContent,
  children,
}: {
  topContent?: React.ReactNode,
  children: React.ReactNode,
}) {
  useEffect(() => {
    if (!!window.Telegram.WebApp.initData) {
      window.Telegram.WebApp.headerColor = '#1a232a';
      window.Telegram.WebApp.requestFullscreen();
      window.Telegram.WebApp.disableVerticalSwipes();
      window.Telegram.WebApp.lockOrientation();
      window.Telegram.WebApp.expand();
    }
  }, []);

  return (
    <>
      <div className="holder">
        <div className="holder__top">
          { topContent }
        </div>
        <div className="holder__bottom">
          { children }
        </div>
        <div className="menu">
          <NavLink to={APP_ROUTES.HOME.INDEX}>
            <SVGDashboard />
            <span>
              Dashboard
            </span>
          </NavLink>
          <NavLink to={APP_ROUTES.WALLET.INDEX}>
            <SVGWallet />
            <span>
              Wallet
            </span>
          </NavLink>
          <NavLink to={APP_ROUTES.INVEST.INDEX}>
            <SVGGold />
            <span>
              Invest
            </span>
          </NavLink>
          <NavLink to={APP_ROUTES.CAREER.INDEX}>
            <SVGCareer />
            <span>
              Career
            </span>
          </NavLink>
          <NavLink to={APP_ROUTES.TEAM.INDEX}>
            <SVGTeam />
            <span>
              Team
            </span>
          </NavLink>
        </div>
      </div>
      {createPortal(
        <Loader />,
        document.body,
      )}
    </>
  );
}