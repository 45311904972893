export default function SVGCopy() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="12"
      fill="none"
      viewBox="0 0 11 12"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.78 10.598c0 .304-.246.55-.55.551H1.424a.551.551 0 01-.551-.55V2.973c0-.304.247-.55.55-.55H8.23c.304 0 .55.247.55.55v7.624zM11 8.228V.603a.552.552 0 00-.55-.551H3.643a.551.551 0 00-.55.55V1.8H8.23c.649 0 1.175.526 1.176 1.175v5.804h1.044c.304 0 .55-.246.55-.55z"
        clipRule="evenodd"
        opacity="0.9"
      ></path>
    </svg>
  );
}