import AppHolder from '@/components/AppHolder';
import { NavLink } from 'react-router-dom';
import SVGSettings from '@/assets/svg/SVGSettings';
import SVGTether from '@/assets/svg/SVGTether';
import NO_AVATAR from '@/assets/img/no-avatar.png';
import CHART_IMAGE from '@/assets/img/chart.png';
import BARS_IMAGE from '@/assets/img/bars.png';
import CASH_IMAGE from '@/assets/img/cash.png';
import TEAM_IMAGE from '@/assets/img/team.png';
import SVGCopy from '@/assets/svg/SVGCopy';

export default function Home() {
  return (
    <AppHolder topContent={<Top />}>
      <div className="stats">
        <div className="stats__block">
          <img src={CHART_IMAGE} alt="" />
          <div className="stats__block--title">
            Invested
          </div>
          <div className="stats__block--value">
            <SVGTether />
            0.00
            <span>
              USDT
            </span>
          </div>
        </div>
        <div className="stats__block">
          <img src={BARS_IMAGE} alt="" />
          <div className="stats__block--title">
            Gold Mined
          </div>
          <div className="stats__block--value">
            <SVGTether />
            0.00
            <span>
              GR
            </span>
          </div>
        </div>
        <div className="stats__block">
          <img src={CASH_IMAGE} alt="" />
          <div className="stats__block--title">
            Earnings
          </div>
          <div className="stats__block--value">
            <SVGTether />
            0.00
            <span>
              USDT
            </span>
          </div>
        </div>
        <div className="stats__block">
          <img src={TEAM_IMAGE} alt="" />
          <div className="stats__block--title">
            Bonuses
          </div>
          <div className="stats__block--value">
            <SVGTether />
            0.00
            <span>
              USDT
            </span>
          </div>
        </div>
      </div>
    </AppHolder>
  );
}

function Top() {
  return (
    <>
      <div className="profile">
        <NavLink to="" className="profile__settings-link">
          <SVGSettings />
        </NavLink>
        <div className="profile__avatar">
          <div>
            <img src={NO_AVATAR} alt="" />
          </div>
        </div>
        <div className="profile__info">
          <div className="profile__info--status">
            <span>
              Partner
            </span>
          </div>
          <div className="profile__info--name">
            Mamkin
            <br />
            Minecrafter
          </div>
          <div className="profile__info--username">
            <span>
              @MamkinMinecrafter
            </span>
          </div>
        </div>
      </div>
      <div className="invite-link__title">
        Your invite link
      </div>
      <div className="invite-link">
        <div className="invite-link__field">
          <input type="text" value="http://example.com/r/MamkinMinecrafter" />
          <button><SVGCopy /></button>
        </div>
      </div>
    </>
  );
}
