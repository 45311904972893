import { ToastContainer } from 'react-toastify';
import MainApp from '@/components/MainApp';

import '@/scss/App.scss';
import 'react-toastify/dist/ReactToastify.css';

declare global {
  interface Window {
    Telegram: any
  }
}

function App() {
  return (
    <>
      <ToastContainer />
      <MainApp />
    </>
  )
}

export default App
