export default function SVGGold() {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.29976 20.2587H12.1331C12.2017 20.2586 12.2694 20.2422 12.3304 20.2109C12.3915 20.1796 12.4443 20.1343 12.4844 20.0786C12.5245 20.0229 12.5508 19.9585 12.5611 19.8907C12.5715 19.8228 12.5656 19.7535 12.5439 19.6884L10.522 13.6217C10.4932 13.5355 10.4381 13.4604 10.3644 13.4072C10.2907 13.354 10.2021 13.3254 10.1112 13.3253H3.32256C3.23157 13.3253 3.14289 13.3539 3.06908 13.4071C2.99527 13.4603 2.94008 13.5354 2.91133 13.6217L0.889392 19.6884C0.867718 19.7535 0.8618 19.8227 0.872127 19.8906C0.882453 19.9584 0.908728 20.0228 0.94879 20.0784C0.988853 20.1341 1.04156 20.1795 1.10257 20.2108C1.16359 20.2421 1.23117 20.2585 1.29976 20.2587Z"
        fill="currentColor"
      />
      <path
        d="M25.1098 19.6884L23.0883 13.6217C23.0595 13.5354 23.0043 13.4603 22.9305 13.4071C22.8567 13.3539 22.768 13.3253 22.677 13.3253H15.888C15.797 13.3253 15.7083 13.3539 15.6345 13.4071C15.5607 13.4603 15.5055 13.5354 15.4768 13.6217L13.4548 19.6884C13.4331 19.7536 13.4272 19.823 13.4376 19.8909C13.448 19.9588 13.4744 20.0233 13.5146 20.079C13.5548 20.1347 13.6077 20.18 13.6689 20.2112C13.7301 20.2425 13.7978 20.2587 13.8665 20.2587H24.6998C24.7684 20.2586 24.8361 20.2422 24.8972 20.2109C24.9582 20.1796 25.011 20.1343 25.0511 20.0786C25.0912 20.0229 25.1175 19.9585 25.1279 19.8907C25.1382 19.8228 25.1323 19.7535 25.1106 19.6884H25.1098Z"
        fill="currentColor"
      />
      <path
        d="M17.0226 5.82124C16.9938 5.73492 16.9386 5.65984 16.8648 5.60664C16.791 5.55344 16.7023 5.52482 16.6113 5.52484H9.3894C9.29841 5.52482 9.20973 5.55344 9.13592 5.60664C9.06211 5.65984 9.00692 5.73492 8.97817 5.82124L6.9558 11.8879C6.9341 11.9531 6.92819 12.0224 6.93857 12.0903C6.94894 12.1582 6.97529 12.2226 7.01545 12.2783C7.05561 12.334 7.10843 12.3793 7.16956 12.4106C7.23069 12.4419 7.29837 12.4582 7.36703 12.4582H18.6337C18.7023 12.4581 18.77 12.4418 18.831 12.4105C18.8921 12.3792 18.9449 12.3338 18.985 12.2781C19.0251 12.2224 19.0514 12.158 19.0617 12.0902C19.0721 12.0223 19.0662 11.953 19.0445 11.8879L17.0226 5.82124Z"
        fill="currentColor"
      />
    </svg>
  );
}
