export const APP_ROUTES = {
  HOME: {
    INDEX: '/',
  },
  WALLET: {
    INDEX: '/wallet',
  },
  INVEST: {
    INDEX: '/invest',
  },
  CAREER: {
    INDEX: '/career',
  },
  TEAM: {
    INDEX: '/team'
  }
}