export default function SVGTether() {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.5208 19.8716C30.7589 22.9435 29.103 25.7205 26.7624 27.8511C24.4219 29.9816 21.502 31.37 18.3721 31.8406C15.2423 32.3112 12.0432 31.8428 9.1796 30.4947C6.31603 29.1466 3.91671 26.9793 2.28523 24.2672C0.653747 21.555 -0.136571 18.4199 0.0142791 15.2585C0.165129 12.097 1.25037 9.0514 3.13267 6.50692C5.01498 3.96245 7.60975 2.0335 10.5887 0.964157C13.5676 -0.105184 16.7968 -0.266859 19.8676 0.499591C23.9801 1.52602 27.5171 4.14258 29.702 7.77469C31.8869 11.4068 32.541 15.7576 31.5208 19.8716Z"
        fill="#26A17B"
      />
      <g opacity={0.24}>
        <path
          d="M15.9979 31.995C14.6926 31.9858 13.3933 31.8179 12.1285 31.4951C9.0668 30.7238 6.30178 29.0637 4.18173 26.7239C2.06167 24.3841 0.6814 21.4693 0.214756 18.3466C-0.251888 15.2239 0.215965 12.0329 1.55939 9.17557C2.90281 6.31824 5.06173 3.92234 7.76421 2.28964C9.87774 1.01807 12.2567 0.252449 14.7151 0.0525997C17.1736 -0.14725 19.6449 0.224087 21.9361 1.13759C24.2272 2.0511 26.2761 3.48203 27.9227 5.31856C29.5692 7.1551 30.7687 9.3475 31.4276 11.7244C32.0864 14.1014 32.1867 16.5985 31.7206 19.0206C31.2545 21.4427 30.2346 23.7242 28.7406 25.6869C27.2467 27.6495 25.3192 29.2402 23.1086 30.3344C20.8981 31.4287 18.4645 31.9971 15.9979 31.995ZM15.9979 2.99953C13.0986 3.00178 10.2833 3.97331 7.99967 5.75964C5.71603 7.54597 4.09519 10.0445 3.3949 12.858C2.93536 14.5291 2.81465 16.2752 3.03987 17.9936C3.26509 19.712 3.8317 21.368 4.70636 22.8642C5.58102 24.3604 6.74607 25.6666 8.13297 26.7059C9.51987 27.7452 11.1006 28.4967 12.7822 28.9161C14.4638 29.3355 16.2123 29.4144 17.9248 29.1481C19.6374 28.8818 21.2793 28.2757 22.7542 27.3654C24.229 26.4552 25.5069 25.2592 26.5127 23.8478C27.5185 22.4364 28.2319 20.8382 28.611 19.147C29.0825 17.2312 29.1128 15.2333 28.6998 13.3041C28.2868 11.3748 27.4411 9.56448 26.2266 8.00962C25.0121 6.45476 23.4604 5.19594 21.6885 4.32807C19.9167 3.4602 17.9709 3.00593 15.9979 2.99953Z"
          fill="white"
        />
      </g>
      <g opacity={0.3}>
        <path
          d="M31.0408 10.5617L0.205592 18.5654C-0.431112 14.632 0.423076 10.6024 2.60097 7.26558C4.77886 3.92873 8.12366 1.52478 11.9807 0.524256C15.8377 -0.476271 19.9293 -0.00134562 23.4546 1.85608C26.9799 3.7135 29.6851 6.81969 31.0408 10.5667V10.5617Z"
          fill="white"
        />
      </g>
      <path
        d="M28.6054 19.1484C27.9831 21.6427 26.6351 23.8965 24.7318 25.6247C22.8285 27.3529 20.4555 28.4779 17.9129 28.8574C15.3702 29.2369 12.7722 28.8539 10.4472 27.7568C8.12223 26.6598 6.17481 24.8979 4.85121 22.694C3.52761 20.4901 2.88728 17.9431 3.01121 15.3753C3.13513 12.8075 4.01774 10.3341 5.54741 8.26789C7.07709 6.20168 9.18512 4.6355 11.6049 3.76739C14.0247 2.89928 16.6476 2.76824 19.1419 3.39084C22.4863 4.22564 25.3622 6.35474 27.1369 9.3098C28.9116 12.2649 29.4399 15.8039 28.6054 19.1484Z"
        fill="#26A17B"
      />
      <path
        d="M23.9975 15.9989C23.9975 14.9991 21.2529 14.1492 17.6035 13.9542V12.3495H22.1578V9H9.70473V12.3495H14.259V13.9642C10.6796 14.1692 8 14.9991 8 15.9989C8 16.9987 10.6796 17.8286 14.259 18.0386V23.8077H17.6035V18.0436C21.2529 17.8486 23.9975 16.9987 23.9975 15.9989ZM15.9987 16.9987C12.0844 16.9987 8.90986 16.3938 8.90986 15.644C8.90986 15.0091 11.1845 14.4791 14.259 14.3292V16.2739C14.8139 16.3039 15.3988 16.3239 15.9987 16.3239C16.5987 16.3239 17.0936 16.3239 17.6035 16.2839V14.3192C20.748 14.4591 23.1026 14.9991 23.1026 15.639C23.0876 16.3888 19.9131 16.9987 15.9987 16.9987Z"
        fill="white"
      />
    </svg>
  );
}