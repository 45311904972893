import { APP_ROUTES } from "@/features/constants";
import Home from "@/pages/Home";
import { Route, Routes } from "react-router-dom";

export default function MainRoutes() {
  return (
    <Routes>
      <Route path={APP_ROUTES.HOME.INDEX} element={<Home />} />
    </Routes>
  );
}